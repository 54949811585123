<script>
import {listFields as list, deleteFieldForm} from '@/domain/form/services/field'
import {UTooltip} from 'uloc-vue'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import {listName, listStorage} from "../config/listField"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import SlCheckbox from "@/reuse/input/Checkbox"
import windowField from '../window/field'
import {inputTypes} from "@/components/forms/input/dynamic/Helper"

const filters = {
  id: null,
  active: [true],
  deleted: false,
  search: null,
}

export default {
  name: 'FormFields',
  mixins: [DefaultMixin, ListMixin, GlobalPersonMixin],
  components: {
    //SlCheckbox,
    //DateInput,
    ErpInput,
    ErpSField,
    ErpSelect,
    //UTooltip
  },
  props: ['form'],
  data() {
    const _filters = JSON.parse(JSON.stringify(filters))
    return {
      listName: listName,
      listType: 'a',
      filters: _filters
    }
  },
  watch: {
  },
  computed: {
    types () {
      return inputTypes
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.table.serverPagination.rowsPerPage = 100
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let extraFilters = []

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)
      this.filters.active && extraFilters.push(`&active=${this.filters.active.join(',')}`)
      this.form && extraFilters.push(`&form=${this.form.id}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'order'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : 'false'}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    label(a, b) {
      return a
    },
    windowField,
    abrir (id) {
      this.windowField(id)
    },
    novoFormulario () {
      this.windowField()
    },
    excluir (id) {
      console.log('Excluir...')
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir campo-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteFieldForm(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Campo excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
  }
}
</script>

<template>
  <div class="vistoria-container">
    <e-row class="m-t-n m-b-md">
      <e-col style="width: 100%; text-align: right">
        <u-btn label="Novo Campo" no-caps color="green" @click="novoFormulario"/>
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
        <div class="end font-14 bg-white flex-zero flex no-wrap">
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input shortkey="F3" v-model="filters.search" @keydown.enter="pesquisar"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 190px; min-width: 190px">
            <erp-s-field
                view="ll"
                label="Status"
            >
              <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                          v-model="filters.active" />
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 50px" >
            <u-btn @click="load" icon="refresh" flat size="sm" />
          </e-col>
        </div>
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="form-table dense"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
<!--          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props"
              :props="props" style="cursor: default">
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="name" :props="props" class="">
            <a @click="abrir(props.row.id)">{{ props.row.name }}</a>
          </u-td>
          <u-td key="type" :props="props" class="">
            <span v-if="props.row.type" class="u-chip u-chip-detail font-bold font-12">{{ types[props.row.type].label }}</span>
          </u-td>
          <u-td key="topic" :props="props" class="">
            <span v-if="props.row.topic" class="">{{ props.row.topic.name }}</span>
          </u-td>
          <u-td key="required" :props="props" class="">
            <span v-if="props.row.required" class="text-positive">Sim</span><span class="text-negative" v-else>Não</span>
          </u-td>
          <u-td key="order" :props="props" class="">
            {{ props.row.order|number }}
          </u-td>
          <u-td key="active" :props="props" class="">
            <span v-if="props.row.active" class="text-positive">Sim</span><span class="text-negative" v-else>Não</span>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <list-menu
                    @abrir="abrir(props.row.id)"
                    @excluir="excluir(props.row.id)"
                    />
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>

      <!--<div class="flex justify-between">
        <div class="m-t">
          <u-btn icon="print" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
          </u-btn>
          <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
          </u-btn>
        </div>
        <div>

        </div>
      </div>-->
    </div>
  </div>
</template>
