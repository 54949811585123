import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'sl.forms.field'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'name', active: true, sortable: true, ordering: 2},
    {label: 'Tipo', name: 'type', active: true, sortable: true, ordering: 3},
    {label: 'Tópico', name: 'topic', active: true, sortable: true, ordering: 3},
    {label: 'Obrigatório', name: 'required', active: true, sortable: true, ordering: 3},
    {label: 'Posição', name: 'order', active: true, sortable: true, ordering: 3},
    {label: 'Ativo', name: 'active', active: true, sortable: true, ordering: 4},
], listVersion)
